export default {
  General: 'Allgemein',
  'Edit Profile': 'Profil bearbeiten',
  Logout: 'Logout',
  Teams: 'Teams',
  Roles: 'Rollen',
  'Add new user': 'Nutzer hinzufügen',
  'Edit user': 'Nutzer bearbeiten',
  'Edit organization': 'Organisation bearbeiten',
  'Subscription management': 'Abonnementverwaltung',
  Back: 'Zurück',
  Next: 'Weiter',
  Language: 'Sprache',
  'Color Mode': 'Farbschema',
  English: 'Englisch',
  German: 'Deutsch',
  Light: 'Hell',
  Dark: 'Dunkel',
  Dashboard: 'Dashboard',
  'Show Users List': 'Nutzerliste anzeigen',
  On: 'An',
  Off: 'Aus',
  Tracking: 'Tracking',
  'Auto time tracking': 'Auto. Zeiterfassung',
  'If set off, changing your status will not create a time entry.':
    'Wenn deaktiviert, wird die Änderung des Statuses keinen Zeiteintrag erstellen.',
  'Auto status update': 'Auto. Status-Update',
  'If set off, adding a time entry will not update your status.':
    'Wenn deaktiviert, wird das Hinzufügen eines Zeiteintrags den Status nicht aktualisieren.',
  'Add a new User': 'Nutzer hinzufügen',
  'Email*': 'Email*',
  'Password*': 'Passwort*',
  Role: 'Rolle',
  Save: 'Speichern',
  'Manage your Teams': 'Teams verwalten',
  'Team name': 'Teamname',
  Manager: 'Manager',
  'Human Resource': 'Human Resource',
  'Edit organization details': 'Organisationsdetails bearbeiten',
  Name: 'Name',
  Owner: 'Manager',
  'Organization Roles': 'Rollen verwalten',
  'Role name': 'Rollenname',
  'Manage your subscription': 'Verwalten Sie Ihr Abonnement',
  'Subscription started': 'Abonnementbeginn',
  'Billing circle started': 'Abrechnungszyklus gestartet',
  'Billing circle ends': 'Abrechnungszyklus endet',
  'Subscription interval': 'Abonnementintervall',
  'Billing amount': 'Abrechnungsbetrag',
  Canceled: 'Gekündigt',
  'Access till': 'Zugang bis',
  'Upgrade subscription': 'Abonnement upgraden',
  'Manage subscription': 'Abonnement verwalten',
  Update: 'Update',
  'Admin permission': 'Admin Rechte',
  'New Password': 'Neues Passwort',
  'Repeat Password': 'Passwort wiederholen',
  Information: 'Information',
  'e.g. "Out for summer break."': 'z.B. "Im Sommerurlaub"',
  'Weekly hours': 'Arbeitsstunden',
  'Annual leave': 'Urlaubstage',
  'Work days': 'Arbeitstage',
  Username: 'Nutzername',
  'First name': 'Vorname',
  'Last name': 'Nachname',
  'Zip Code': 'Postleitzahl',
  City: 'Stadt',
  Street: 'Straße',
  Number: 'Nummer',
  Country: 'Land',
  Phone: 'Telefon',
  Mobile: 'Mobil',
  Skills: 'Skills',
  '(e.g. Development, Excel)': '(z.B. Entwicklung, Excel)',
  'Past invoices': 'Abrechnungen',
  'Current plan': 'Aktueller Plan',
  'Trial expiration date': 'Ablauf Testphase',
  'Learn more': 'Mehr erfahren',
  Startup: 'Startup',
  Basic: 'Basic',
  Premium: 'Premium',
  Corporate: 'Corporate',
  'Repeat Email': 'E-Mail wiederholen',
  Permission: 'Berechtigungen',
  'Team & Role': 'Team & Rolle',
  'Note of absence': 'Abwesenheitsnotiz',
  'General Information': 'Allgemeine Informationen',
  Worktime: 'Arbeitszeit',
  'Delete User?': 'Nutzer löschen?',
  Users: 'Nutzer',
  'Delete Organization?': 'Organisation löschen?',
}
