export default {
  'Sign Up': 'Sign Up',
  'Start with Email': 'Start with Email',
  'Welcome Back': 'Welcome Back',
  "Don't have an account?": "Don't have an account?",
  '(min. 4 letters)': '(min. 4 letters)',
  'Already have an account?': 'Already have an account?',
  'Reset Password': 'Reset Password',
  'Verify Account': 'Verify Account',
  'Verification Code': 'Verification Code',
  'Account already verified?': 'Account already verified?',
  Password: 'Password',
  'Forgot password': 'Forgot password',
  'Enter your email address': 'Enter your email address',
  Submit: 'Submit',
  Login: 'Login',
  Organization: 'Organization',
  'Min. char': 'Min. char',
  Uppercase: 'Uppercase',
  Number: 'Number',
  Special: 'Special',
  Start: 'Start',
  Email: 'Email',
  'demo-01':
    'Please note: All data generated in a demo account will not be stored permanently.',
  'Sign up': 'Sign up',
  Login: 'Login',
  Language: 'Language',
  German: 'German',
  English: 'English',
  'Legal notice': 'Legal notice',
  'Data privacy': 'Data privacy',
  'Contact us': 'Contact us',
  Verify: 'Verify',
  'Select language': 'Select language',
  'Get started with Sharedex': 'Get started with Sharedex',
  'Create an account to test all our features for free.':
    'Create an account to test all our features for free.',
  'No credit card required - Unsubscribe at any time':
    'No credit card required - Unsubscribe at any time',
  'Start now': 'Start now',
  'A verification email has been sent to:':
    'A verification email has been sent to:',
}
