<template>
  <Notification />
  <SubscriptionWarning />
  <RotationWarning />

  <div class="main" :class="{ auth: userId, 'max-width': maxWidth }">
    <MainNavbar class="main-navbar" />
    <DashboardNavbar v-if="userId" class="dashboard-navbar" />
    <Router class="router" />
  </div>
</template>

<script>
import { onBeforeMount, watch, computed } from 'vue'
import { useStore } from 'vuex'

import Notification from '@components/notification/Notification.vue'
import SubscriptionWarning from '@components/warning/Subscription.vue'
import RotationWarning from '@components/warning/Rotation.vue'
import MainNavbar from '@components/navigation/main/Main.vue'
import Router from '@components/router/Router.vue'
import DashboardNavbar from '@components/navigation/dashboard/Dashboard.vue'

export default {
  name: 'App',
  components: {
    Notification,
    SubscriptionWarning,
    RotationWarning,
    MainNavbar,
    Router,
    DashboardNavbar,
  },
  setup() {
    const store = useStore()

    const userId = computed(() => {
      return store.state.user.id
    })

    const maxWidth = computed(() => {
      return !store.state.dashboardNav.expanded
    })

    watch(userId, async (id) => {
      if (id) await store.dispatch('init')
    })

    onBeforeMount(async () => {
      store.dispatch('autoLogin')
      store.commit('calcScreen')

      window.addEventListener('resize', () => {
        store.commit('calcScreen')
      })
    })

    return {
      userId,
      maxWidth,
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap');
@import '@assets/variables.scss';
@import '@assets/media.scss';
@import '@assets/classes.scss';
@import '@assets/transition.scss';

body {
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

#app {
  color: var(--font);
  background-color: var(--base);
  width: 100%;
  height: 100%;

  .main {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 55px calc(100% - 55px);
    grid-template-columns: 1fr;
    grid-template-areas:
      'main-navbar'
      'router';

    &.auth {
      transition: all 0.2s linear;
      grid-template-columns: 300px calc(100% - 300px);
      grid-template-areas:
        'dashboard-navbar main-navbar'
        'dashboard-navbar router';

      &.max-width {
        transition: all 0.2s linear;
        grid-template-columns: 75px calc(100% - 75px);
      }

      @media #{$mobile} {
        grid-template-rows: 55px calc(100% - 55px);
        grid-template-columns: 20% 80% !important;
        grid-template-areas:
          'dashboard-navbar main-navbar'
          'router router';
      }

      .main-navbar {
        grid-area: main-navbar;
      }

      .dashboard-navbar {
        grid-area: dashboard-navbar;
      }

      .router {
        grid-area: router;
      }

      .mobile-navbar {
        grid-area: mobile-navbar;
      }
    }
  }
}
</style>
