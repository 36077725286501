<template>
  <div class="navigation column">
    <Category
      href="/time/list"
      icon="fa-solid fa-list"
      text="List"
      :subCategory="true"
    />

    <Category
      href="/time/team-calendar"
      icon="fa-solid fa-calendar"
      text="Calendar"
      :subCategory="true"
    />

    <Category
      href="/time/statistics"
      icon="fa-solid fa-chart-simple"
      text="Insights"
      :subCategory="true"
    />
  </div>
</template>

<script>
import Category from '@components/navigation/dashboard/components/Category'

export default {
  name: 'Navigation',
  components: { Category },
  setup() {},
}
</script>

<style lang="scss" scoped>
@import '@assets/media.scss';

.navigation {
  width: 100%;
  align-items: center;
}
</style>
