export default {
  'Sign Up': 'Registrieren',
  'Start with Email': 'Mit E-Mail starten',
  'Welcome Back': 'Willkommen zurück',
  "Don't have an account?": 'Noch kein Account vorhanden?',
  '(min. 4 letters)': '(min. 4 Buchstaben)',
  'Already have an account?': 'Bereits ein Account?',
  'Reset Password': 'Passwort zurücksetzen',
  'Verify Account': 'Account verifizieren',
  'Verification Code': 'Verifizierungscode',
  'Account already verified?': 'Account bereits verifiziert?',
  Password: 'Passwort',
  'Forgot password': 'Passwort vergessen',
  'Enter your email address': 'Wie ist deine E-Mail?',
  Submit: 'Senden',
  Login: 'Login',
  Organization: 'Unternehmen',
  'Min. char': 'Min. Char',
  Uppercase: 'Groß',
  Number: 'Nummer',
  Special: 'Sonderz.',
  Start: 'Start',
  Email: 'Email',
  'demo-01':
    'Bitte beachten: Alle Daten, die in einem Demokonto generiert werden, werden nicht dauerhaft gespeichert.',
  'Sign up': 'Registrieren',
  Login: 'Login',
  Language: 'Sprache',
  German: 'Deutsch',
  English: 'Englisch',
  'Legal notice': 'Impressum',
  'Contact us': 'Kontakt',
  Verify: 'Verifizieren',
  'Select language': 'Sprache wählen',
  'Get started with Sharedex': 'Starte mit Sharedex',
  'Create an account to test all our features for free.':
    'Erstelle ein Konto, um alle unsere Funktionen kostenlos zu testen.',
  'No credit card required - Unsubscribe at any time':
    'Keine Kreditkarte erforderlich - Jederzeit abbestellbar',
  'Start now': 'Starte jetzt',
  'A verification email has been sent to:':
    'Eine Bestätigungs-E-Mail wurde gesendet an:',
}
