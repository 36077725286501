<template>
  <div class="navigation row">
    <div class="link-container">
      <router-link to="" @click="$router.go(-1)">
        <i class="fa-regular fa-circle-left"></i>
      </router-link>
    </div>

    <div class="link-container">
      <router-link to="/" class="logo row">
        <i class="fa-solid fa-circle-nodes"></i>
        <span> Sharedex </span>
      </router-link>
    </div>

    <div class="link-container">
      <div @click="$emit('showLanguages')">
        <i class="fa-solid fa-language"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Authentication',
  emits: ['showLanguages'],
  setup() {},
}
</script>

<style lang="scss" scoped>
.navigation {
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 40px);
  padding: 0 20px;
  height: 100%;
  border-bottom: 1px solid var(--dark-2);

  .link-container {
    width: calc(100% / 3);
    display: flex;

    .sidebar-icon {
      align-items: center;
      justify-content: flex-start;

      i {
        font-size: 2rem;
        cursor: pointer;
        color: var(--dark-10);
      }
    }

    a {
      height: 100%;

      &.logo {
        align-items: center;

        span {
          color: var(--dark-10);
          font-size: 2rem;
          margin-left: 10px;

          @media (orientation: portrait) {
            display: none;
          }
        }
      }
    }

    &:nth-of-type(2) {
      justify-content: center;
    }

    &:nth-of-type(3) {
      justify-content: flex-end;
    }

    i {
      color: var(--dark-10);
      font-size: 2rem;
      cursor: pointer;
    }
  }
}
</style>
