import { createStore } from 'vuex'
import { io } from 'socket.io-client'
import router from '../router/index.js'

let os = 'web'
if (
  navigator.userAgent.includes('Android') ||
  navigator.userAgent.includes('iOS') ||
  navigator.userAgent.includes('iPhone') ||
  navigator.userAgent.includes('iPad')
)
  os = 'mobile'

let url = 'https://sharedex-a12fc96d12a8.herokuapp.com'
let mode = 'live'
if (window.location.href.includes('http://localhost:8080')) {
  url = 'http://localhost:3000'
  mode = 'development'
} else if (window.location.href.includes('http://192.168.178.26:8080')) {
  url = 'http://192.168.178.26:3000'
  mode = 'development'
} else if (window.location.href.includes('http://demo.localhost:8080')) {
  url = 'http://localhost:3000'
  mode = 'demo'
} else if (window.location.href.includes('https://demo.sharedex.co')) {
  url = 'https://sharedex-demo-ed1a17b0847e.herokuapp.com'
  mode = 'demo'
} else if (window.location.href.includes('https://shrdx.co')) {
  url = 'https://sharedex-test-5ec3206fa2ed.herokuapp.com'
  mode = 'test'
}

let language = 'en'
let supportedLanguages = ['en', 'de']
let userLanguage =
  navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0]
if (supportedLanguages.includes(userLanguage)) {
  language = userLanguage
}

export default createStore({
  modules: {},

  state() {
    return {
      url: url,
      mode: mode,
      os: os,
      token: null,
      tokenExpirationDate: null,
      language: language,
      user: { id: null },
      dashboardNav: { expanded: window.innerWidth > 1000 ? true : false },
      overlay: { show: false },
      deleteOverlay: { show: false },
      loading: { show: false, timeout: null, id: '' },
      notification: { show: false, messages: [] },
      screen: {
        width: 0,
        height: 0,
        orientation: '',
        device: '',
        keyboardOpen: false,
        rotationWarning: false,
      },
    }
  },

  mutations: {
    setLanguage(state, lang) {
      if (supportedLanguages.includes(lang)) state.language = lang
      else state.language = 'en'
    },

    calcScreen(state) {
      const width = window.innerWidth
      const height = window.innerHeight

      const availHeight = window.screen.availHeight
      const availWidth = window.screen.availWidth

      let keyboardOpen = false
      if (os === 'mobile' && height / availHeight < 0.7) keyboardOpen = true

      let orientation = 'landscape'
      if (os === 'web') orientation = width > height ? 'landscape' : 'portrait'
      else if (os === 'mobile')
        orientation = availWidth > availHeight ? 'landscape' : 'portrait'

      let device = 'desktop'
      if (orientation === 'portrait') {
        if (width <= 450) device = 'mobile'
        else if (width <= 850) device = 'tablet'
      } else if (keyboardOpen) {
        if (availHeight <= 480) device = 'mobile'
        else if (availHeight <= 900) device = 'tablet'
      } else {
        if (height <= 480) device = 'mobile'
        else if (height <= 900) device = 'tablet'
      }

      let rotationWarning = device === 'mobile' && orientation === 'landscape'

      state.screen.width = width
      state.screen.height = height
      state.screen.orientation = orientation
      state.screen.device = device
      state.screen.keyboardOpen = keyboardOpen
      state.screen.rotationWarning = rotationWarning
    },

    setUser(state, user) {
      state.user.id = user._id
      state.user = { ...state.user, ...user }
    },

    setLoadingId(state, id = '') {
      if (state.loading.show) {
        setTimeout(() => {
          clearTimeout(state.loading.timeout)

          state.loading.id = ''
          state.loading.show = false
        }, 500)
      } else if (id === '' && state.loading.timeout) {
        clearTimeout(state.loading.timeout)

        state.loading.id = ''
        state.loading.show = false
      } else {
        state.loading.timeout = setTimeout(() => {
          state.loading.show = true
          state.loading.id = id
        }, 250)
      }
    },

    showNotification(state, notification) {
      state.notification.show = true

      let created = new Date().getTime()

      state.notification.messages.push({
        type: notification.type,
        text: notification.message,
        created: created,
      })

      state.notification.messages.sort((a, b) => {
        return a.created - b.created
      })

      setTimeout(() => {
        if (state.notification.messages.length > 0)
          for (let [i, n] of state.notification.messages.entries()) {
            if (n.created === created) state.notification.messages.splice(i, 1)
          }
        else state.notification.show = false
      }, 5000)
    },

    hideNotification(state, notification) {
      for (let [i, n] of state.notification.messages.entries()) {
        if (n.created === notification.created)
          state.notification.messages.splice(i, 1)
      }

      if (state.notification.messages.length <= 0)
        state.notification.show = false
    },

    showOverlay(state, show) {
      state.overlay.show = show
    },

    showDeleteOverlay(state, show) {
      state.deleteOverlay.show = show
    },

    expandDarboardNav(state, show) {
      state.dashboardNav.expanded = show
    },
  },

  actions: {
    async init({ state, commit, dispatch }) {
      commit('resetTimeFilter', {
        email: state.user.email,
        id: state.user.id,
      })

      const res = await dispatch('getUser', state.user.id)
      commit('setUser', res)

      await Promise.all([
        dispatch('getOrganization'),
        dispatch('getUserSettings'),
        dispatch('getUsers'),
        dispatch('getTeams'),
        dispatch('getRoles'),
      ])

      commit('setLanguage', state.settings.general.language)

      state.authentication.socket = io(state.url, {
        extraHeaders: { organization: state.user.organization },
      })

      state.authentication.socket.on('user-status-updated', ({ users }) => {
        commit('setUsers', users)
        for (let u of users) {
          if (u._id !== state.user.id) continue
          commit('setStatus', u.status)
        }
      })
    },

    /*
      ROUTE /demo/
    */
    async startDemo({ commit, rootState }) {
      const url = `${rootState.url}/demo`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()

      if (data.error)
        return commit('showNotification', {
          type: 'error',
          message: data.error
            ? data.error
            : 'Something went wrong, please try again.',
        })

      if (data.success && data.user && data.token) {
        commit('showNotification', {
          type: 'info',
          message: data.success,
        })

        commit('setTimeFilter', { type: 'id', value: data.user.id })

        const expirationDate = new Date().getTime() + 12 * 60 * 60000

        rootState.token = data.token
        rootState.expirationDate = expirationDate
        rootState.user = data.user

        localStorage.setItem('token', data.token)
        localStorage.setItem('tokenExpirationDate', expirationDate)
        localStorage.setItem('user', JSON.stringify(data.user))

        rootState.authentication.socket = io(rootState.url, {
          extraHeaders: {
            organization: rootState.user.organization,
          },
        })

        router.push('/dashboard')
      }
    },
  },
})
